import { useQuery } from 'react-query'

import { useFilterBySort } from '@plandi/common'
import { messagesApi } from '../messages.api'
import { messagesSortOptions, SHOP_MESSAGES_DATA } from '../messages.constants'

export const useQueryShopMessages = () => {
  const { sort } = useFilterBySort()

  const query = useQuery([SHOP_MESSAGES_DATA, sort], () =>
    messagesApi.getShopMessages(sort as messagesSortOptions)
  )

  return query
}
