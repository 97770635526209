import React, { FC } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuIcon from '@material-ui/icons/Menu'
import { useNewHeaderStyles } from './new-header.styles'
import Image from 'next/image'
import Container from '@material-ui/core/Container'
import { Fade, Link, useMediaQuery, useTheme } from '@material-ui/core'
import NextLink from 'next/link'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useRouter } from 'next/router'
import Button from '@material-ui/core/Button'
// import { ReactComponent as FlagRu } from '../../../../assets/flagRu.svg'
// import { ReactComponent as FlagEn } from '../../../../assets/flagEn.svg'
import {
  getAuthSignInUrl,
  getServiceURL,
  // useChangeLocale,
  useLocale
} from '@plandi/common'
import { NewHeaderMobileDrawer } from './new-header-mobile-drawer'

export const NewHeader: FC = () => {
  const classes = useNewHeaderStyles()
  const marketButtonRef = React.useRef<HTMLButtonElement>(null)
  const jobButtonRef = React.useRef<HTMLButtonElement>(null)
  // const langButtonRef = React.useRef<HTMLButtonElement>(null)
  const [marketDropOpen, setMarketDropOpen] = React.useState(false)
  const [jobDropOpen, setJobDropOpen] = React.useState(false)
  // const [langDropOpen, setlangDropOpen] = React.useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const router = useRouter()
  const locale = useLocale()
  // const { setLocale } = useChangeLocale()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(960))
  return (
    <AppBar position="fixed" classes={{ root: classes.root }}>
      <Toolbar>
        {isSmallScreen && (
          <IconButton
            className={classes.menuIcon}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsDrawerOpen((prev) => !prev)}
          >
            <MenuIcon className={classes.menuIcon} color={'inherit'} />
          </IconButton>
        )}

        {/*<Typography variant="h6" noWrap>*/}
        {/*  Material-UI*/}
        {/*</Typography>*/}
        <Container className={classes.container} maxWidth={false}>
          <div className={classes.wrapper}>
            <Image
              width="136"
              height="40"
              src="/assets/logo-header.svg"
              alt=""
              layout="fixed"
            />
            <nav className={classes.nav}>
              {!isSmallScreen && (
                <>
                  {/*<NextLink href={'/'} passHref>*/}
                  {/*  <Link*/}
                  {/*    color="textPrimary"*/}
                  {/*    underline="none"*/}
                  {/*    className={classes.link}*/}
                  {/*  >*/}
                  {/*    О компании*/}
                  {/*  </Link>*/}
                  {/*</NextLink>*/}
                  <Typography
                    className={classes.link}
                    onClick={() => setMarketDropOpen((prev) => !prev)}
                    ref={marketButtonRef}
                    color={marketDropOpen ? 'secondary' : 'textPrimary'}
                  >
                    Маркет
                    <KeyboardArrowDownIcon />
                  </Typography>
                  <Menu
                    elevation={0}
                    id="market-menu"
                    anchorEl={marketButtonRef.current}
                    keepMounted
                    open={marketDropOpen}
                    onClose={() => setMarketDropOpen(false)}
                    TransitionComponent={Fade}
                    classes={{ paper: classes.menuRoot }}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/shop/projects')
                      }}
                    >
                      Проекты
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/shop/models')
                      }}
                    >
                      BIM-модели
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/shop/goods')
                      }}
                    >
                      Товары
                    </MenuItem>
                  </Menu>
                  <Typography
                    className={classes.link}
                    ref={jobButtonRef}
                    onClick={() => setJobDropOpen((prev) => !prev)}
                    color={jobDropOpen ? 'secondary' : 'textPrimary'}
                  >
                    Работа
                    <KeyboardArrowDownIcon />
                  </Typography>
                  <Menu
                    elevation={0}
                    id="job-menu"
                    anchorEl={jobButtonRef.current}
                    keepMounted
                    open={jobDropOpen}
                    onClose={() => setJobDropOpen(false)}
                    TransitionComponent={Fade}
                    classes={{ paper: classes.menuRoot }}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/job/applicants')
                      }}
                    >
                      Заказы
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/job/employers')
                      }}
                    >
                      Специалисты
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/job/companies')
                      }}
                    >
                      Компании
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        router.push('/job/factories')
                      }}
                    >
                      Заводы
                    </MenuItem>
                  </Menu>
                  <Link
                    color="textPrimary"
                    underline="none"
                    className={classes.link}
                    href={`https://${
                      process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                        ? 'stage-office'
                        : 'office'
                    }.plandi.ru/board`}
                  >
                    Офис
                  </Link>
                  <NextLink href={'/blog'} passHref>
                    <Link
                      color="textPrimary"
                      underline="none"
                      className={classes.link}
                    >
                      Блог
                    </Link>
                  </NextLink>
                  <NextLink href={'/shop/subscriptions'} passHref>
                    <Link
                      color="textPrimary"
                      underline="none"
                      className={classes.link}
                    >
                      Подписки
                    </Link>
                  </NextLink>
                  {/*<Button*/}
                  {/*  ref={langButtonRef}*/}
                  {/*  onClick={() => {*/}
                  {/*    setlangDropOpen((prev) => !prev)*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {locale === 'ru' ? <FlagRu /> : <FlagEn />}*/}
                  {/*  <KeyboardArrowDownIcon />*/}
                  {/*</Button>*/}
                  {/*<Menu*/}
                  {/*  elevation={0}*/}
                  {/*  id="lang-menu"*/}
                  {/*  anchorEl={langButtonRef.current}*/}
                  {/*  keepMounted*/}
                  {/*  open={langDropOpen}*/}
                  {/*  onClose={() => setlangDropOpen(false)}*/}
                  {/*  TransitionComponent={Fade}*/}
                  {/*  classes={{ paper: classes.menuRoot }}*/}
                  {/*>*/}
                  {/*  <MenuItem*/}
                  {/*    className={classes.menuItem}*/}
                  {/*    onClick={() => {*/}
                  {/*      setLocale('ru')*/}
                  {/*      setlangDropOpen(false)*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <FlagRu /> Русский*/}
                  {/*  </MenuItem>*/}
                  {/*  <MenuItem*/}
                  {/*    className={classes.menuItem}*/}
                  {/*    onClick={() => {*/}
                  {/*      setLocale('en')*/}
                  {/*      setlangDropOpen(false)*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <FlagEn /> English*/}
                  {/*  </MenuItem>*/}
                  {/*</Menu>*/}
                </>
              )}
              <Button
                color={'secondary'}
                variant={'contained'}
                classes={{ root: classes.signInButtonRoot }}
                onClick={() =>
                  router.push(getAuthSignInUrl(getServiceURL(locale).main))
                }
              >
                Войти
              </Button>
            </nav>
          </div>
        </Container>
      </Toolbar>
      <NewHeaderMobileDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </AppBar>
  )
}
