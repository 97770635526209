import React, { useRef } from 'react'
import {
  TranslationDictionary,
  getServiceURL,
  useDisclosure
} from '@plandi/common'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import IconButton from '@material-ui/core/IconButton'

import { useLayoutHeaderMenuStyles } from './layout-header-menu.styles'
import { Link } from '@material-ui/core'

type LayoutMenuItem = {
  title: string
  href: string
  active?: boolean
}

type Props = {
  userDidSignOut?: boolean
  translate?: TranslationDictionary['data']
  locale: string
}

export const LayoutHeaderMenu: React.FC<Props> = ({ locale, translate }) => {
  const main: LayoutMenuItem[] = [
    {
      title: translate?.['market']?.['title'] ?? 'Проектный маркет',
      href: `${getServiceURL(locale).shop}/models`
    },
    {
      title: translate?.['job']?.['title2'] ?? 'Фриланс и работа',
      href: `${getServiceURL(locale).job}/applicants`
    },
    {
      title: translate?.office?.title ?? 'Онлайн Офис',
      href: `${getServiceURL(locale).office}/board`
    },
    {
      title: translate?.bignav?.blog?.title ?? 'Блог',
      href: `${getServiceURL(locale).blog}/articles`
    }
  ]

  const classes = useLayoutHeaderMenuStyles()

  const menuRef = useRef()

  const mainDisclosure = useDisclosure()

  return (
    <div className={classes.container}>
      <>
        <IconButton
          onClick={mainDisclosure.onToggle}
          innerRef={menuRef}
          size="small"
          className={classes.sections}
        >
          {translate?.['job-site']?.sections ?? 'Разделы'}
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          anchorEl={menuRef.current}
          open={mainDisclosure.isOpen}
          onClose={mainDisclosure.onClose}
        >
          {main.map((tab) => (
            <Link
              href={tab.href}
              key={tab.href}
              color="inherit"
              underline="none"
            >
              <MenuItem button>{tab.title}</MenuItem>
            </Link>
          ))}
        </Menu>
      </>
    </div>
  )
}
