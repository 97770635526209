import { getApiClient } from '@plandi/common'
import { components } from '@plandi/common/src/generated'
import { messageSearchOptions, messagesSortOptions } from './messages.constants'

export const messagesApi = {
  getAdvertsMessages: (sortingOptions?: messagesSortOptions) =>
    getApiClient()
      .get<{
        data: components['schemas']['ProjectAdvertsGetNotificationsResourceItem']
      }>('/v1/profiles/specialists/chats', {
        params: { sort: sortingOptions }
      })
      .then(({ data }) => data.data),

  getShopMessages: (sortingOptions?: messagesSortOptions) =>
    getApiClient()
      .get<{
        data: components['schemas']['ProjectMarketGetNotificationsResourceItem']
      }>('/v1/market/messages/chats', {
        params: { sort: sortingOptions }
      })
      .then(({ data }) => data.data),
  getUnreadMessages: () =>
    getApiClient()
      .get('/v1/chat/count')
      .then(({ data }) => data.data),
  getProjectsChats: (params?: messageSearchOptions) =>
    getApiClient()
      .get('/v1/market/projects/chats', { params: params })
      .then(({ data }) => data.data),
  getModelsChats: (params?: messageSearchOptions) =>
    getApiClient()
      .get('/v1/market/models/chats', { params: params })
      .then(({ data }) => data.data),
  getCompaniesChats: (params?: messageSearchOptions) =>
    getApiClient()
      .get<components['schemas']['CompanyMessagesResponse']>(
        '/v1/profiles/companies/chats',
        { params: params }
      )
      .then(({ data }) => data.data),
  getSpecialistChats: (params?: messageSearchOptions) =>
    getApiClient()
      .get<components['schemas']['SpecialistMessagesResponse']>(
        '/v1/profiles/specialists/chats',
        {
          params: { ...params }
        }
      )
      .then(({ data }) => data.data),
  getFactoriesChats: (params?: messageSearchOptions) =>
    getApiClient()
      .get<components['schemas']['FactoryMessagesResponse']>(
        '/v1/profiles/plants/chats',
        { params: params }
      )
      .then(({ data }) => data.data),

  deleteChatSpecialists: (id: number) =>
    getApiClient()
      .delete(`/v1/profiles/specialists/chat/${id}`)
      .then(({ data }) => data),

  deleteChatCompanies: (id: number) =>
    getApiClient()
      .delete(`/v1/profiles/companies/chat/${id}`)
      .then(({ data }) => data),

  deleteChatPlants: (id: number) =>
    getApiClient()
      .delete(`/v1/profiles/plants/chat/${id}`)
      .then(({ data }) => data),

  deleteChatProjects: (id: number) =>
    getApiClient()
      .delete(`/v1/market/projects/chat/${id}`)
      .then(({ data }) => data),

  deleteChatModels: (id: number) =>
    getApiClient()
      .delete(`/v1/market/models/chat/${id}`)
      .then(({ data }) => data),

  closeChatSpecialists: (
    requestBody: { status: 'active' | 'decline' },
    id: number
  ) =>
    getApiClient()
      .post(`/v1/profiles/specialists/chat/${id}`, requestBody)
      .then(({ data }) => data),

  closeChatCompanies: (
    requestBody: { status: 'active' | 'decline' },
    id: number
  ) =>
    getApiClient()
      .post(`/v1/profiles/companies/chat/${id}`, requestBody)
      .then(({ data }) => data),

  closeChatPlants: (
    requestBody: { status: 'active' | 'decline' },
    id: number
  ) =>
    getApiClient()
      .post(`/v1/profiles/plants/chat/${id}`, requestBody)
      .then(({ data }) => data),

  closeChatProjects: (
    requestBody: { status: 'active' | 'decline' },
    id: number
  ) =>
    getApiClient()
      .post(`/v1/market/projects/chat/${id}`, requestBody)
      .then(({ data }) => data),

  closeChatModels: (
    requestBody: { status: 'active' | 'decline' },
    id: number
  ) =>
    getApiClient()
      .post(`/v1/market/models/chat/${id}`, requestBody)
      .then(({ data }) => data)
}
