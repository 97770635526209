import React, { FC } from 'react'
import Container from '@material-ui/core/Container'
import { Grid, Link } from '@material-ui/core'
import { ReactComponent as LogoFooter } from '../../../../assets/White.svg'
import { ReactComponent as Telegram } from '../../../../assets/new-socials/Telegram.svg'
import { ReactComponent as Youtube } from '../../../../assets/new-socials/YouTube.svg'
import { ReactComponent as XIcon } from '../../../../assets/new-socials/X.svg'
import { ReactComponent as LinkedIn } from '../../../../assets/new-socials/Linkedin.svg'
import { ReactComponent as Facebook } from '../../../../assets/new-socials/Facebook.svg'
import { ReactComponent as Vk } from '../../../../assets/new-socials/Vk.svg'
import { useNewFooterStyles } from './new-footer.styles'
import Typography from '@material-ui/core/Typography'
import NextLink from 'next/link'
import clsx from 'clsx'

export const NewFooter: FC = () => {
  const classes = useNewFooterStyles()
  return (
    <Container maxWidth={false} className={classes.root}>
      <Container className={classes.wrapper}>
        <Grid container spacing={6}>
          <Grid item xs md className={classes.logoContainer}>
            <LogoFooter />
            <div className={classes.socials}>
              <Link
                target={'_blank'}
                href="https://t.me/joinchat/AAAAAEuYq1OcwjEVhZjN6A"
                aria-label={'telegram'}
              >
                <Telegram />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.youtube.com/channel/UCAIG4a-FPIxW_jU0d3-AM_w?view_as=subscriber"
                aria-label={'youtube'}
              >
                <Youtube />
              </Link>
              <Link
                target={'_blank'}
                href="https://twitter.com/plan_di"
                aria-label={'x'}
              >
                <XIcon />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.linkedin.com/in/plan-di"
                aria-label={'linkedin'}
              >
                <LinkedIn />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.facebook.com/plandi.ru.official?mibextid=LQQJ4d"
                aria-label={'facebook'}
              >
                <Facebook />
              </Link>
              <Link
                target={'_blank'}
                href="https://vk.com/plandiruofficial"
                aria-label={'vkontakte'}
              >
                <Vk />
              </Link>
            </div>
            <Typography className={classes.copy}>
              &copy; Все права защищены &quot;Plandi.ru&quot; 2024
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={6}
            // spacing={2}
            className={classes.linksContainer}
          >
            <Grid item xs className={classes.linksColumn}>
              {/*<NextLink href={'/'} passHref>*/}
              <Typography className={clsx(classes.link, classes.headLink)}>
                О компании
              </Typography>
              {/*</NextLink>*/}
              <NextLink href={'/shop/subscriptions'} passHref>
                <Link className={classes.link}>Подписки</Link>
              </NextLink>
              <NextLink href={'#reviews-slider'} passHref>
                <Link className={classes.link}>Отзывы</Link>
              </NextLink>
              <NextLink href={'/questions?question=job'} passHref>
                <Link className={classes.link}>FAQ</Link>
              </NextLink>
              <NextLink href={'/'} passHref>
                <Link className={classes.link}>Контакты</Link>
              </NextLink>
            </Grid>
            <Grid item xs className={classes.linksColumn}>
              <NextLink href={'/shop'} passHref>
                <Link className={clsx(classes.link, classes.headLink)}>
                  Маркет
                </Link>
              </NextLink>
              <NextLink href={'/shop/projects'} passHref>
                <Link className={classes.link}>Проекты</Link>
              </NextLink>
              <NextLink href={'/shop/models'} passHref>
                <Link className={classes.link}>BIM-модели</Link>
              </NextLink>
              <NextLink href={'/shop/goods'} passHref>
                <Link className={classes.link}>Товары</Link>
              </NextLink>
            </Grid>
            <Grid item xs className={classes.linksColumn}>
              <NextLink href={'/job'} passHref>
                <Link className={clsx(classes.link, classes.headLink)}>
                  Работа
                </Link>
              </NextLink>
              <NextLink href={'/job/applicants'} passHref>
                <Link className={classes.link}>Заказы</Link>
              </NextLink>
              <NextLink href={'/job/employers'} passHref>
                <Link className={classes.link}>Специалисты</Link>
              </NextLink>
              <NextLink href={'/job/companies'} passHref>
                <Link className={classes.link}>Компании</Link>
              </NextLink>
              <NextLink href={'/job/factories'} passHref>
                <Link className={classes.link}>Заводы</Link>
              </NextLink>
            </Grid>
            <Grid item xs className={classes.linksColumn}>
              <Link
                className={clsx(classes.link, classes.headLink)}
                href={`https://${
                  process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                    ? 'stage-office'
                    : 'office'
                }.plandi.ru/board`}
              >
                Офис
              </Link>
              <NextLink href={'/blog/articles'} passHref>
                <Link className={clsx(classes.link, classes.headLink)}>
                  Блог
                </Link>
              </NextLink>
            </Grid>
          </Grid>
          <Grid item xs className={clsx(classes.linksColumn, classes.docLinks)}>
            <Typography className={clsx(classes.link, classes.headLink)}>
              Документы и соглашения
            </Typography>

            <Link
              className={classes.link}
              href={
                'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.pdf'
              }
            >
              Пользовательское соглашение
            </Link>

            <Link
              className={classes.link}
              href={
                'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'
              }
            >
              Политика конфиденциальности
            </Link>

            <Link
              className={classes.link}
              href={
                'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9.pdf'
              }
            >
              Политика обработки персональных данных пользователей
            </Link>

            <Link
              className={classes.link}
              href={
                'https://storage.yandexcloud.net/plandi-storage/storage/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BE%D0%B1_%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8_%D1%81%D0%B0%D0%B9%D1%82%D0%B0_plandi_ru.pdf'
              }
            >
              Соглашение об использовании сайта plandi.ru
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
