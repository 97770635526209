import React, { FC } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Image from 'next/image'
import NoSsr from '@material-ui/core/NoSsr'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import {
  NotificationIcon,
  getServiceURL,
  useQueryUser,
  useLocale,
  useTranslation,
  UserMenu,
  getToken
} from '@plandi/common'

import { useLayoutHeaderStyles } from './layout-header.styles'
import { LayoutHeaderUserMenu, userMenuMain } from '../layout-header-user-menu'
import { LayoutBurgerMenu } from '../LayoutBurgerMenu'
import { useQueryCountNewNotifications } from '@plandi/common/src/components/notifications/common'
import { Link, Typography } from '@material-ui/core'
import { useRouter } from 'next/router'
import { LayoutHeaderMenu } from '../layout-header-menu'

type LayoutHeaderProps = {
  title?: string
}

export const LayoutHeader: FC<LayoutHeaderProps> = () => {
  const token = getToken()

  const { data: countNewNotifications } = useQueryCountNewNotifications({
    select: (data) =>
      (Object.keys(data.count ?? {}) as Array<keyof typeof data.count>).reduce(
        (acc, key) => acc + (data?.count?.[key] ?? 0),
        0
      ),
    enabled: !!token
  })

  const { data: user } = useQueryUser()
  const router = useRouter()
  const classes = useLayoutHeaderStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(1550))
  const isTablet = useMediaQuery(theme.breakpoints.up(1280))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500))
  const locale = useLocale()
  const { data: translation } = useTranslation()
  return (
    <AppBar position="fixed" color="transparent" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.headerLeft}>
          <Link href={getServiceURL(locale).main}>
            {isSmallScreen ? (
              <Image
                width="50"
                height="40"
                src="/assets/logo-not-text.png"
                alt=""
                layout="fixed"
              />
            ) : (
              <Image
                width="136"
                height="40"
                src="/assets/logo-header.svg"
                alt=""
                layout="fixed"
              />
            )}
          </Link>
          {router.pathname.includes('profile') ? (
            <>
              <span className={classes.rect} />
              <Link
                href={getServiceURL(locale).main + '/profile'}
                color="inherit"
                underline="none"
              >
                <Typography variant="h6" className={classes.title}>
                  {translation?.account}
                </Typography>
              </Link>
            </>
          ) : null}
          {/* {isTablet && (
            <div className={classes.lang}>
              <Link href={getServiceURL('ru').main + router.asPath}>
                <Button
                  disabled={locale === 'ru'}
                  size="small"
                  className={classes.btnRu}
                >
                  Ru
                </Button>
              </Link>
              <div className={classes.linkDivider} />
              <Link href={getServiceURL('env').main + router.asPath}>
                <Button
                  disabled={locale === 'en'}
                  size="small"
                  className={classes.btnEng}
                >
                  En
                </Button>
              </Link>
            </div>
          )} */}
          {!isDesktop && (
            <LayoutHeaderMenu translate={translation} locale={locale} />
          )}
        </div>

        {isDesktop && (
          <div className={classes.usermenu}>
            {userMenuMain(translation, locale)?.map((item) => {
              return (
                <Button
                  className={clsx(classes.headerLink)}
                  key={item.link}
                  href={item.link}
                >
                  {item.title}
                </Button>
              )
            })}
          </div>
        )}
        <div className={classes.headerRight}>
          <NoSsr>
            {isTablet && Boolean(user) && <NotificationIcon />}
            <LayoutHeaderUserMenu
              onLogoutSuccess={() => router.push(getServiceURL(locale).main)}
            />
            {!isTablet && Boolean(user) && (
              <UserMenu
                redirectTo={getServiceURL(locale).main}
                onLogoutSuccess={() => router.push(getServiceURL(locale).main)}
                userMenu={
                  <LayoutBurgerMenu
                    countNewNotifications={countNewNotifications}
                    translate={translation}
                    locale={locale}
                  />
                }
              />
            )}
          </NoSsr>
        </div>
      </Toolbar>
    </AppBar>
  )
}
