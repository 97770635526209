import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useNewHeaderStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff'
    },
    container: {
      maxWidth: '1360px',
      padding: '0 !important',
      [theme.breakpoints.down(960)]: {
        paddingLeft: '0 !important'
      }
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    menuIcon: {
      color: '#000'
    },
    link: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 'normal',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down(960)]: {
        fontSize: '16px',
        justifyContent: 'space-between'
      }
    },
    nav: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '32px'
    },
    menuRoot: {
      top: '45px !important',
      borderRadius: '8px',
      border: '1px solid #EEEEEC'
    },
    menuItem: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 'normal',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px'
    },
    signInButtonRoot: {
      textTransform: 'none',
      fontSize: '14px'
    },
    drawer: {
      width: '100%'
    },
    mobileWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    mobileToolbar: {
      borderBottom: '1px solid #EEEEEC'
    },
    mobileLinkList: {
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '24px',
      paddingTop: '50px',
      flex: '1 1 100% '
    },
    mobileLinkSubList: {
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '24px',
      paddingTop: '24px',
      paddingLeft: '12px'
    },
    socials: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '16px',
      margin: '24px 0'
    },
    copy: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
      textAlign: 'center',
      color: '#AAACA2',
      marginBottom: '24px'
    },
    mobileDrawerContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    drawerFooter: {
      borderTop: '1px solid #EEEEEC'
    }
  })
)
