import React from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Avatar, Drawer, ListItemAvatar } from '@material-ui/core'
import clsx from 'clsx'
import {
  EmailOutlined,
  SettingsOutlined,
  StarOutline,
  AccountBoxOutlined
} from '@material-ui/icons'

import { useLayoutStyles } from '../layout.styles'
import {
  SERVICES_URL,
  getServiceURL,
  useLocale,
  useMutationLogout,
  useQueryUser,
  useTranslation
} from '@plandi/common'

type DrawerUserMenuProps = {
  isOpen: boolean
  onClose: () => void
}

export const DrawerUserMenu: React.FC<DrawerUserMenuProps> = ({
  isOpen,
  onClose
}) => {
  const classes = useLayoutStyles()
  const profileUrl = `${SERVICES_URL.main}/profile`
  const messageUrl = `${SERVICES_URL.main}/messages`
  const favoriteUrl = `${SERVICES_URL.main}/favorites`
  const anketsUrl = `${SERVICES_URL.job}/questionnaires`

  const { data: user } = useQueryUser({
    notifyOnChangeProps: ['data'],
    staleTime: 1000 * 60
  })

  const mutationLogout = useMutationLogout({
    onSuccess: () => {
      location.replace('/')
    }
  })

  const router = useRouter()
  const { data: translation } = useTranslation()
  const locale = useLocale()
  return (
    <Drawer open={isOpen} onClose={onClose} className={classes.drawer}>
      <List className={classes.tabletMenuList}>
        <ListItem button component="a" href={profileUrl}>
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar className={classes.avatar} src={user?.avatarUrl}>
              {user?.name?.slice(0, 1)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            className={clsx(classes.userMenuListItemText, classes.usernameText)}
            primary={user?.name}
            secondary={user?.email}
          />
        </ListItem>

        <div className={classes.userMenuLinksContainer}>
          <span className={clsx(classes.userMenuTitle)}>{`${
            translation?.['account'] ?? 'Мой профиль'
          }:`}</span>

          <div className={classes.userMenuLinks}>
            <div className={classes.userMenuLinksTop}>
              <Image
                src="/assets/new-landing/header/advert.svg"
                layout="fixed"
                width="16"
                alt=""
                height="16"
              />
              <span
                className={clsx(
                  classes.userLinksTitle,
                  classes.userLinksTitleTablet
                )}
              >
                {translation?.['job']?.['title2'] ?? 'Фриланс и работа'}
              </span>
            </div>
            <div
              className={clsx(
                classes.userLinksBottom,
                classes.userLinksBottomTablet
              )}
            >
              <a href={SERVICES_URL.job + '/tenders/me'}>
                {translation?.['menu']?.['my-tenders'] ?? 'Мои заказы'}
              </a>
              <a href={SERVICES_URL.job + '/contract/me'}>
                {translation?.['menu']?.['contracts'] ?? 'Договоры'}
              </a>
              <a
                href={SERVICES_URL.main + '/profile/purchases?type=specialist'}
              >
                {translation?.['menu']?.['subscriptions'] ?? 'Подписки'}
              </a>
            </div>
          </div>

          <div className={classes.userMenuLinks}>
            <div className={classes.userMenuLinksTop}>
              <Image
                src="/assets/new-landing/header/market.svg"
                layout="fixed"
                width="16"
                alt=""
                height="16"
              />
              <span
                className={clsx(
                  classes.userLinksTitle,
                  classes.userLinksTitleTablet
                )}
              >
                {translation?.['market']?.['title'] ?? 'Проектный маркет'}
              </span>
            </div>
            <div
              className={clsx(
                classes.userLinksBottom,
                classes.userLinksBottomTablet
              )}
            >
              <a href={SERVICES_URL.shop + '/projects/me'}>
                {translation?.menu?.['my-projects'] ?? 'Мои проекты'}
              </a>
              <a href={SERVICES_URL.shop + '/models/me'}>
                {translation?.menu?.['my-models'] ?? 'Мои модели'}
              </a>
              <a href={SERVICES_URL.main + '/profile/purchases'}>
                {translation?.menu?.subscriptions ?? 'Подписки'}
              </a>
              <a href={getServiceURL(locale).shop + '/goods/me'}>
                {locale === 'en' ? 'My goods' : 'Мои товары'}
              </a>
            </div>
          </div>

          <div className={classes.userMenuLinks}>
            <div className={classes.userMenuLinksTop}>
              <Image
                src="/assets/new-landing/header/connected-system.svg"
                layout="fixed"
                width="16"
                alt=""
                height="16"
              />
              <span
                className={clsx(
                  classes.userLinksTitle,
                  classes.userLinksTitleTablet
                )}
              >
                {translation?.office?.title ?? 'Онлайн офис'}
              </span>
            </div>
            <div
              className={clsx(
                classes.userLinksBottom,
                classes.userLinksBottomTablet
              )}
            >
              <a href={SERVICES_URL.office + '/projects'}>
                {translation?.menu?.['my-projects'] ?? 'Мои проекты'}
              </a>
            </div>
          </div>

          <div className={classes.userMenuLinks}>
            <div className={classes.userMenuLinksTop}>
              <Image
                src="/assets/new-landing/header/blog.svg"
                layout="fixed"
                width="16"
                height="16"
              />
              <span
                className={clsx(
                  classes.userLinksTitle,
                  classes.userLinksTitleTablet
                )}
              >
                Блог
              </span>
            </div>
            <div
              className={clsx(
                classes.userLinksBottom,
                classes.userLinksBottomTablet
              )}
            >
              <a href={SERVICES_URL.blog + '/articles/me'}>
                {translation?.menu?.['my-articles'] ?? 'Мои статьи'}
              </a>
            </div>
          </div>
        </div>
        <ListItem
          style={{ marginLeft: 5 }}
          button
          onClick={() => router.push(anketsUrl)}
        >
          <AccountBoxOutlined style={{ width: 18, height: 18 }} />
          <ListItemText
            style={{ marginLeft: 5 }}
            className={classes.userMenuListItemText}
          >
            {translation?.['personal-account']?.['my-ankets'] ?? 'Мои анкеты'}
          </ListItemText>
        </ListItem>
        <ListItem
          style={{ marginLeft: 5 }}
          button
          onClick={() => router.push(messageUrl)}
        >
          <EmailOutlined style={{ width: 18, height: 18 }} />
          <ListItemText
            style={{ marginLeft: 5 }}
            className={classes.userMenuListItemText}
          >
            {translation?.menu?.messages ?? 'Сообщения'}
          </ListItemText>
        </ListItem>
        <ListItem
          style={{ marginLeft: 5 }}
          button
          onClick={() => router.push(favoriteUrl)}
        >
          <StarOutline style={{ width: 18, height: 18 }} />
          <ListItemText
            style={{ marginLeft: 5 }}
            className={classes.userMenuListItemText}
          >
            {translation?.favorites?.title ?? 'Избранное'}
          </ListItemText>
        </ListItem>
        <ListItem
          style={{ marginLeft: 5 }}
          button
          onClick={() => router.push(profileUrl)}
        >
          <SettingsOutlined style={{ width: 18, height: 18 }} />
          <ListItemText
            style={{ marginLeft: 5 }}
            className={classes.userMenuListItemText}
          >
            {translation?.['office-site']?.['settings'] ?? 'Настройки'}
          </ListItemText>
        </ListItem>
        <ListItem
          style={{ marginLeft: 6 }}
          button
          onClick={() => mutationLogout.mutate()}
        >
          <Image
            width={13}
            height={13}
            layout="fixed"
            src="/assets/icons/exit.svg"
          />
          <ListItemText
            style={{ marginLeft: 8 }}
            className={classes.userMenuListItemText}
          >
            {translation?.menu?.logout ?? 'Выход'}
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  )
}
