import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'
// import Button from '@material-ui/core/Button'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
// import Menu from '@material-ui/core/Menu'
import {
  Drawer,
  // Fade,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
// import MenuItem from '@material-ui/core/MenuItem'
import NextLink from 'next/link'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import React, { FC } from 'react'
import { useNewHeaderStyles } from './new-header.styles'
// import { useChangeLocale, useLocale } from '@plandi/common'
// import { ReactComponent as FlagRu } from '../../../../assets/flagRu.svg'
// import { ReactComponent as FlagEn } from '../../../../assets/flagEn.svg'
import { ReactComponent as Telegram } from '../../../../assets/new-socials/Telegram.svg'
import { ReactComponent as Youtube } from '../../../../assets/new-socials/YouTube.svg'
import { ReactComponent as XIcon } from '../../../../assets/new-socials/X.svg'
import { ReactComponent as LinkedIn } from '../../../../assets/new-socials/Linkedin.svg'
import { ReactComponent as Facebook } from '../../../../assets/new-socials/Facebook.svg'
import { ReactComponent as Vk } from '../../../../assets/new-socials/Vk.svg'

type stateSpatcher = (prevState: boolean) => boolean
interface NewHeaderMobileDrawerProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: (state: boolean | stateSpatcher) => void
}

export const NewHeaderMobileDrawer: FC<NewHeaderMobileDrawerProps> = ({
  isDrawerOpen,
  setIsDrawerOpen
}) => {
  const classes = useNewHeaderStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(960))
  // const langButtonRef = useRef<HTMLButtonElement>(null)
  // const [langDropOpen, setlangDropOpen] = React.useState(false)
  const [marketCollapseOpen, setMarketCollapseOpen] = React.useState(false)
  const [jobCollapseOpen, setJobCollapseOpen] = React.useState(false)
  const [docCollapseOpen, setDocCollapseOpen] = React.useState(false)
  // const locale = useLocale()
  // const { setLocale } = useChangeLocale()
  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      classes={{ paper: classes.drawer }}
    >
      <Toolbar className={classes.mobileToolbar}>
        {isSmallScreen && (
          <IconButton
            className={classes.menuIcon}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsDrawerOpen((prev) => !prev)}
          >
            <CloseIcon className={classes.menuIcon} color={'inherit'} />
          </IconButton>
        )}
        <Container className={classes.container} maxWidth={false}>
          <div className={clsx(classes.wrapper, classes.mobileWrapper)}>
            <nav className={classes.nav}>
              <>
                {/*<Button*/}
                {/*  ref={langButtonRef}*/}
                {/*  onClick={() => {*/}
                {/*    setlangDropOpen((prev) => !prev)*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {locale === 'ru' ? <FlagRu /> : <FlagEn />}*/}
                {/*  <KeyboardArrowDownIcon />*/}
                {/*</Button>*/}
                {/*<Menu*/}
                {/*  elevation={0}*/}
                {/*  id="lang-menu"*/}
                {/*  anchorEl={langButtonRef.current}*/}
                {/*  keepMounted*/}
                {/*  open={langDropOpen}*/}
                {/*  onClose={() => setlangDropOpen(false)}*/}
                {/*  TransitionComponent={Fade}*/}
                {/*  classes={{ paper: classes.menuRoot }}*/}
                {/*>*/}
                {/*  <MenuItem*/}
                {/*    className={classes.menuItem}*/}
                {/*    onClick={() => {*/}
                {/*      setLocale('ru')*/}
                {/*      setlangDropOpen(false)*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <FlagRu /> Русский*/}
                {/*  </MenuItem>*/}
                {/*  <MenuItem*/}
                {/*    className={classes.menuItem}*/}
                {/*    onClick={() => {*/}
                {/*      setLocale('en')*/}
                {/*      setlangDropOpen(false)*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <FlagEn /> English*/}
                {/*  </MenuItem>*/}
                {/*</Menu>*/}
              </>
            </nav>
          </div>
        </Container>
      </Toolbar>
      <div className={classes.mobileDrawerContentWrapper}>
        <Container className={classes.mobileLinkList}>
          {/*<NextLink href={'/'} passHref>*/}
          {/*  <Link*/}
          {/*    className={classes.link}*/}
          {/*    color={'textPrimary'}*/}
          {/*    underline={'none'}*/}
          {/*  >*/}
          {/*    О компании*/}
          {/*  </Link>*/}
          {/*</NextLink>*/}
          <div>
            <Typography
              className={classes.link}
              onClick={() => setMarketCollapseOpen((prev) => !prev)}
              component="div"
            >
              Маркет
              {marketCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={marketCollapseOpen}>
              <div className={classes.mobileLinkSubList}>
                <NextLink href={'/shop/projects'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Проекты
                  </Link>
                </NextLink>
                <NextLink href={'/shop/models'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    BIM-модели
                  </Link>
                </NextLink>
                <NextLink href={'/shop/goods'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Товары
                  </Link>
                </NextLink>
              </div>
            </Collapse>
          </div>

          <div>
            <Typography
              className={classes.link}
              onClick={() => setJobCollapseOpen((prev) => !prev)}
              component="div"
            >
              Работа
              {jobCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={jobCollapseOpen}>
              <div className={classes.mobileLinkSubList}>
                <NextLink href={'/job/applicants'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Заказы
                  </Link>
                </NextLink>
                <NextLink href={'/job/employers'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Специалисты
                  </Link>
                </NextLink>
                <NextLink href={'/job/companies'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Компании
                  </Link>
                </NextLink>
                <NextLink href={'/job/factories'}>
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Заводы
                  </Link>
                </NextLink>
              </div>
            </Collapse>
          </div>

          <Link
            className={classes.link}
            color={'textPrimary'}
            underline={'none'}
            href={`https://${
              process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                ? 'stage-office'
                : 'office'
            }.plandi.ru/board`}
          >
            Офис
          </Link>
          <NextLink href={'/blog/articles'} passHref>
            <Link
              className={classes.link}
              color={'textPrimary'}
              underline={'none'}
            >
              Блог
            </Link>
          </NextLink>
          <NextLink href={'/shop/subscriptions'} passHref>
            <Link
              className={classes.link}
              color={'textPrimary'}
              underline={'none'}
            >
              Подписки
            </Link>
          </NextLink>

          <div>
            <Typography
              className={classes.link}
              onClick={() => setDocCollapseOpen((prev) => !prev)}
              component="div"
            >
              Документы и соглашения
              {docCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Typography>
            <Collapse in={docCollapseOpen}>
              <div className={classes.mobileLinkSubList}>
                <NextLink
                  href={
                    'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.pdf'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Пользовательское соглашение
                  </Link>
                </NextLink>
                <NextLink
                  href={
                    'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Политика конфиденциальности
                  </Link>
                </NextLink>
                <NextLink
                  href={
                    'https://storage.yandexcloud.net/plandi-storage/storage/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9.pdf'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Политика обработки персональных данных пользователей
                  </Link>
                </NextLink>
                <NextLink
                  href={
                    'https://storage.yandexcloud.net/plandi-storage/storage/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BE%D0%B1_%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8_%D1%81%D0%B0%D0%B9%D1%82%D0%B0_plandi_ru.pdf'
                  }
                  passHref
                >
                  <Link
                    className={classes.link}
                    color={'textPrimary'}
                    underline={'none'}
                  >
                    Соглашение об использовании сайта plandi.ru
                  </Link>
                </NextLink>
              </div>
            </Collapse>
          </div>
        </Container>
        <div className={classes.drawerFooter}>
          <Container>
            <div className={classes.socials}>
              <Link
                target={'_blank'}
                href="https://t.me/joinchat/AAAAAEuYq1OcwjEVhZjN6A"
              >
                <Telegram />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.youtube.com/channel/UCAIG4a-FPIxW_jU0d3-AM_w?view_as=subscriber"
              >
                <Youtube />
              </Link>
              <Link target={'_blank'} href="https://twitter.com/plan_di">
                <XIcon />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.linkedin.com/in/plan-di"
              >
                <LinkedIn />
              </Link>
              <Link
                target={'_blank'}
                href="https://www.facebook.com/plandi.ru.official?mibextid=LQQJ4d"
              >
                <Facebook />
              </Link>
              <Link target={'_blank'} href="https://vk.com/plandiruofficial">
                <Vk />
              </Link>
            </div>
            <Typography className={classes.copy}>
              &copy; Все права защищены &quot;Plandi.ru&quot; 2024
            </Typography>
          </Container>
        </div>
      </div>
    </Drawer>
  )
}
